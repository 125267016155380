import { DELETE_SUBSCRIPTION } from "./types";
import SubscriptionsApi from "../api/SubscriptionsApi";

export const deleteSubscription = (userId, courseId) => async dispatch => {
  const { data: _data } = await SubscriptionsApi().deleteSubscription(
    userId,
    courseId
  );
  dispatch({
    type: DELETE_SUBSCRIPTION,
    payload: { userId, courseId },
  });
};
