import { DELETE_COURSE } from "./types";
import CoursesApi from "../api/CoursesApi";

export const deleteCourse = course => async dispatch => {
  const { data } = await CoursesApi().deleteCourse(course);

  dispatch({
    type: DELETE_COURSE,
    payload: data,
  });
};
