import {
  GET_COURSES,
  GET_COURSES_BY_TYPE,
  GET_STUDENT_COURSES,
} from "../actions/types";

const INITIAL_STATE = { courses: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSES: {
      const { courses } = action.payload;
      return { ...state, courses };
    }
    case GET_COURSES_BY_TYPE: {
      const { courses } = action.payload;
      return { ...state, courses };
    }
    case GET_STUDENT_COURSES: {
      const { courses } = action.payload;
      return { ...state, courses };
    }
    default:
      return state;
  }
};
