import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getStudentsCourses } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box } from "@mui/material";
import "./StudentCourseListPage.scss";

const cardSx = _theme => ({
  display: "flex",
  padding: "12px 20px",
  border: "1px solid #eee",
  boxShadow: "rgba(0, 0, 0, 0.086) 0px 3px 20px",
  borderRadius: "8px",
  "& a:visited:not(.MuiButtonBase-root)": {
    color: "#4a31f0",
  },
});

const solidButtonSx = {
  "&:hover": { backgroundColor: "#2F6670" },
};

export default function StudentCourseListPage() {
  const courses = useSelector(state => state.courses.courses);
  const userRole = useSelector(state => state.user.role);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudentsCourses());
  }, [dispatch]);
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState(params.searchTerm);

  useEffect(() => {
    setSearchTerm(params.searchTerm);
  }, [params.searchTerm]);

  return (
    <div style={{ padding: "60px 2% 100px" }}>
      <h1 className="mb-4 sm:mb-[60px] mt-0">Mis cursos</h1>
      {courses
        .filter(course => {
          if (!searchTerm) {
            return true;
          }

          if (
            course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            course.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            course.category.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return course;
          }
        })
        .map(course => (
          <Box
            sx={cardSx}
            key={course.id}
            to={`/lectures/${course.id}/`}
            className="items-start flex-col sm:items-center sm:flex-row gap-3 my-4 mx-3 sm:mx-auto sm:w-4/5"
          >
            <h2 style={{ fontSize: "18px", color: "#0c7e75" }}>
              {course.name}
            </h2>
            <div className="sm:ml-auto sm:text-right flex gap-1 sm:gap-4 sm:m-1">
              <Link className="link" to={`/lectures/${course.id}/`}>
                <Button sx={solidButtonSx} variant="contained">
                  Videos
                </Button>
              </Link>

              <Link className="link" to={`/course/${course.id}/links`}>
                <Button sx={solidButtonSx} variant="contained">
                  Links
                </Button>
              </Link>

              <Link className="link" to={`/course/${course.id}/attachments`}>
                <Button sx={solidButtonSx} variant="contained">
                  Materiales
                </Button>
              </Link>

              {userRole === "teacher" && (
                <Link className="link" to={`/course/${course.id}/lectures`}>
                  <Button sx={solidButtonSx} variant="contained">
                    Editar Videos
                  </Button>
                </Link>
              )}
            </div>
          </Box>
        ))}
    </div>
  );
}
