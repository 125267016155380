import { GET_LECTURE } from "./types";
import LecturesApi from "../api/LecturesApi";

export const getLecture = (courseId, lectureId) => async dispatch => {
  const { data } = await LecturesApi().getLecture(courseId, lectureId);
  dispatch({
    type: GET_LECTURE,
    payload: { courseId, lectureId, data: data.lecture },
  });
};
