import { GET_USER_SUBSCRIPTIONS } from "./types";
import SubscriptionsApi from "../api/SubscriptionsApi";

export const getSubscriptions = userId => async dispatch => {
  const { data } = await SubscriptionsApi().getSubscriptions(userId);
  dispatch({
    type: GET_USER_SUBSCRIPTIONS,
    payload: data,
  });
  return "Subscripciones obtenidas con éxito";
};
