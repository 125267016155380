import { UPDATE_COURSE } from "./types";
import CoursesApi from "../api/CoursesApi";

export const updateCourse = course => async dispatch => {
  const { data } = await CoursesApi().updateCourse(course);

  dispatch({
    type: UPDATE_COURSE,
    payload: data,
  });
};
