import { AppBar, Toolbar, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import IPE0 from "../shared/IPElogo";
import TemporaryDrawer from "../Drawer/Drawer";
import SearchBar from "../SearchBar/SearchBar";
import "./TopBar.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions";

const solidButtonSx = {
  backgroundColor: "#014451",
  color: "white",
  "&:hover": { backgroundColor: "#2F6670" },
};
export default function TopBar({ onSearch, search }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.authenticated);

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <TemporaryDrawer sizeBig="large" sizeSmall="large" />
        <Box mx="auto" sx={{ paddingLeft: "1%" }}>
          <a href="/">
            <IPE0
              width={100}
              height={50}
              fill1="grey"
              fill2="#00a6a3"
              style={{ padding: "0px", marginTop: "7px" }}
            />
          </a>
        </Box>
        <SearchBar onSearch={onSearch} search={search} />
        {!isAuthenticated ? (
          <>
            <Link style={{ textDecoration: "none" }} to="/login">
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #014451",
                  color: "#014451",
                }}
                size="large"
              >
                INGRESAR
              </Button>
            </Link>

            <Box mx="auto" sx={{ paddingLeft: "1%" }}>
              <Link style={{ textDecoration: "none" }} to="/signup">
                <Button sx={solidButtonSx} variant="contained" disableElevation>
                  REGISTRARSE
                </Button>
              </Link>
            </Box>
          </>
        ) : (
          <Box mx="auto" sx={{ paddingLeft: "1%" }}>
            <Button
              sx={solidButtonSx}
              variant="contained"
              disableElevation
              onClick={() => dispatch(logout())}
            >
              CERRAR SESION
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
