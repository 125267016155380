import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { setupListeners } from "@reduxjs/toolkit/query";

import MainNavigator from "./navigators/MainNavigator";
import reducers from "./reducers";
import { linksApi } from "./api/LinksApi";
import { quizzApi } from "./api/QuizzApi";

const store = configureStore({
  reducer: {
    ...reducers,
    [linksApi.reducerPath]: linksApi.reducer,
    [quizzApi.reducerPath]: quizzApi.reducer,
  },
  preloadedState: {
    auth: { authenticated: localStorage.getItem("token") },
    user: { role: localStorage.getItem("role") },
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(linksApi.middleware, quizzApi.middleware),
});

setupListeners(store.dispatch);

const theme = createTheme({
  palette: {
    primary: {
      main: "#014451",
    },
    error: {
      main: "#ec5d57",
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        "&:hover": { backgroundColor: "#2F6670" },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainNavigator></MainNavigator>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
