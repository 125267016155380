import { GET_COURSES } from "./types";
import CoursesApi from "../api/CoursesApi";

export const getCourses = () => async dispatch => {
  const { data } = await CoursesApi().getCourses();
  dispatch({
    type: GET_COURSES,
    payload: data,
  });
  return "Cursos obtenidos con éxito";
};
