import { useMemo, forwardRef } from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText } from "@mui/material";

export default function ListItemLink({ text, to, sx }) {
  const CustomLink = useMemo(
    () =>
      forwardRef(function LinkWithRef(linkProps, ref) {
        return <Link ref={ref} to={to} {...linkProps} />;
      }),
    [to]
  );

  return (
    <ListItem button component={CustomLink} sx={sx}>
      <ListItemText primary={text} />
    </ListItem>
  );
}
