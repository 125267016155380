import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base";

const provideTag = (result, _, courseId) => [{ type: "Link", id: courseId }];

export const linksApi = createApi({
  baseQuery: axiosBaseQuery,
  reducerPath: "linksApi",
  tagTypes: ["Link"],
  endpoints(build) {
    return {
      getLinks: build.query({
        query: courseId => ({
          url: `courses/${courseId}/links`,
          method: "GET",
        }),
        transformResponse: data => data?.links,
        providesTags: provideTag,
      }),
      addLink: build.mutation({
        query: ({ courseId, name, url }) => ({
          url: `courses/${courseId}/links`,
          method: "POST",
          data: { name, url },
        }),
        invalidatesTags: data => provideTag(null, null, data.link.courseId),
      }),
      editLink: build.mutation({
        query: ({ courseId, linkId, name, url }) => ({
          url: `courses/${courseId}/links/${linkId}`,
          method: "PUT",
          data: { name, url },
        }),
        invalidatesTags: data => provideTag(null, null, data.link.courseId),
      }),
      deleteLink: build.mutation({
        query: ({ courseId, linkId }) => ({
          url: `courses/${courseId}/links/${linkId}`,
          method: "DELETE",
        }),
        invalidatesTags: data => provideTag(null, null, data.link.courseId),
      }),
    };
  },
});

export const {
  useGetLinksQuery,
  useAddLinkMutation,
  useEditLinkMutation,
  useDeleteLinkMutation,
} = linksApi;
