import { CREATE_LECTURE } from "./types";
import LecturesApi from "../api/LecturesApi";

export const createLecture = (courseId, body) => async dispatch => {
  const newLecture = await new LecturesApi().createLecture(courseId, body);
  dispatch({
    type: CREATE_LECTURE,
    payload: { courseId, lecture: newLecture.data.lecture },
  });
};
