import { GET_ALL_USERS } from "./types";
import UsersApi from "../api/UsersApi";

export const getAllUsers = () => async dispatch => {
  const { data } = await UsersApi().getUsers();
  dispatch({
    type: GET_ALL_USERS,
    payload: data,
  });
  return "Teachers obtenidos con éxito";
};
