import { CREATE_COURSE, UPDATE_COURSE, DELETE_COURSE } from "../actions/types";

const INITIAL_STATE = {
  name: "",
  description: "",
  objective: "",
  groups: [],
  type: "",
  category: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_COURSE: {
      const {
        name,
        description,
        objective,
        groups,
        type,
        category,
      } = action.payload;
      return { ...state, name, description, objective, groups, type, category };
    }
    case UPDATE_COURSE: {
      const {
        name,
        description,
        objective,
        groups,
        type,
        category,
      } = action.payload;
      return { ...state, name, description, objective, groups, type, category };
    }
    case DELETE_COURSE: {
      return { ...state };
    }
    default:
      return state;
  }
};
