import axios from "axios";
import get from "lodash/get";
const { REACT_APP_API_BASE_URL } = process.env;
import { getAuthToken } from "../services/LocalStorageService";
import { clearLocalStorage } from "../services/LocalStorageService";

export const ApiService = () => {
  const options = { baseURL: REACT_APP_API_BASE_URL };
  const authToken = getAuthToken();

  if (authToken) {
    options.headers = { Authorization: authToken };
  }

  const instance = axios.create(options);

  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (get(error, "response.status") === 401) {
        // go to home
        clearLocalStorage();
        document.location.href = "/";
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const axiosBaseQuery = async ({
  url,
  method,
  data,
  params,
  headers,
}) => {
  try {
    const result = await ApiService()({
      url,
      method,
      data,
      params,
      headers,
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
