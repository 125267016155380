import { UPDATE_LECTURE } from "./types";
import LecturesApi from "../api/LecturesApi";

export const updateLecture = (courseId, id, data) => async dispatch => {
  const updatedLecture = await new LecturesApi().updateLecture(
    id,
    courseId,
    data
  );
  dispatch({
    type: UPDATE_LECTURE,
    payload: { courseId, id, data: updatedLecture.data },
  });
};
