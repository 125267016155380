import { DELETE_USER } from "./types";
import UsersApi from "../api/UsersApi";

export const deleteUser = user => async dispatch => {
  const { data } = await UsersApi().deleteUser(user);

  dispatch({
    type: DELETE_USER,
    payload: data,
  });
};
