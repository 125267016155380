import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base";

const provideTag = (result, _, courseId) => [{ type: "Quizz", id: courseId }];

export const quizzApi = createApi({
  baseQuery: axiosBaseQuery,
  tagTypes: ["Quizz"],
  reducerPath: "quizzApi",
  endpoints(build) {
    return {
      getQuizzes: build.query({
        query: courseId => ({
          url: `courses/${courseId}/quizz`,
          method: "GET",
        }),
        transformResponse: data => data?.quizzes,
        providesTags: provideTag,
      }),
      deleteQuizzes: build.mutation({
        query: courseId => ({
          url: `courses/${courseId}/quizz`,
          method: "DELETE",
        }),
        invalidatesTags: provideTag,
      }),
    };
  },
});

export const { useGetQuizzesQuery, useDeleteQuizzesMutation } = quizzApi;
