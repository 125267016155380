import Home from "../components/Home/Home";
import MobileHome from "../components/Home/MobileHome";
import { useNavigate } from "react-router-dom";

export default function HomePage({ isTabletOrMobile, setShowMobileSearchBar }) {
  const navigate = useNavigate();

  const handleCourseCategoryClick = category => {
    navigate(`/coursesList/${category}`);
  };
  return isTabletOrMobile ? (
    <MobileHome
      onCourseCategoryClick={handleCourseCategoryClick}
      setShowMobileSearchBar={setShowMobileSearchBar}
    ></MobileHome>
  ) : (
    <Home onCourseCategoryClick={handleCourseCategoryClick}></Home>
  );
}
