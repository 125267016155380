import { useState, useEffect } from "react";
import CourseList from "../components/CourseList/CourseList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesBytype } from "../actions";

export default function CourseListPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const storeCourses = useSelector(state => state.courses.courses);
  const [courses, setCourses] = useState(storeCourses || []);

  const [type, _setType] = useState(params.type);

  useEffect(() => {
    dispatch(getCoursesBytype(type));
  }, [dispatch, type]);

  useEffect(() => {
    setCourses(storeCourses);
  }, [storeCourses]);

  return <CourseList courses={courses}></CourseList>;
}
