import "./CustomCard.css";

export const CustomCard = props => {
  return (
    <div className="CustomCard">
      <div className="imgs">
        <img src={props.img} alt={props.title} />
      </div>
      <div className="CardText">
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default CustomCard;
