const url = "subscriptions";
import { ApiService } from "./base";

export default function SubscriptionsApi() {
  const getSubscriptions = async userId => {
    const subscriptions = await ApiService().get(url + `/user/${userId}`);
    return subscriptions;
  };

  const deleteSubscription = async (userId, courseId) => {
    const user = await ApiService().delete(
      url + `/course/${courseId}/user/${userId}`
    );
    return user;
  };

  const addSubscription = async (body, courseId) => {
    const user = await ApiService().post("/subscriptions", {
      ...body,
      courseId,
    });
    return user;
  };

  return {
    getSubscriptions,
    deleteSubscription,
    addSubscription,
  };
}
