import { LOGIN } from "./types";
import UsersApi from "../api/UsersApi";
import { setToken, setRole, setUserId } from "../services/LocalStorageService";

export const login = (email, password, fingerprint) => async dispatch => {
  const { data } = await UsersApi().login(email, password, fingerprint);

  setToken(data.token);
  setRole(data.role);
  setUserId(data.id);

  dispatch({
    type: LOGIN,
    payload: data,
  });
};
