import {
  GET_LECTURES,
  UPDATE_LECTURE,
  CREATE_LECTURE,
  DELETE_LECTURE,
  GET_LECTURE,
} from "../actions/types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LECTURES: {
      const { courseId, data } = action.payload;
      return { ...state, [courseId]: data.lectures };
    }
    case GET_LECTURE: {
      const { courseId, lectureId, data } = action.payload;
      const courseLectures = state[courseId];

      // create a new list of lectures and update the lecture if exists
      let found = false;
      const newLectures = courseLectures.map(lecture => {
        if (lecture.id === lectureId) {
          found = true;
          return { ...lecture, ...data };
        }
        return { ...lecture };
      });

      if (!found) {
        newLectures.push(data);
      }

      return { ...state, [courseId]: newLectures };
    }

    case UPDATE_LECTURE: {
      const { courseId, id, data } = action.payload;
      const courseLectures = state[courseId];

      // create a new list of lectures updating the lecture to modify
      const newLectures = courseLectures.map(lecture => {
        if (lecture.id === id) {
          return { ...lecture, ...data };
        }
        return { ...lecture };
      });

      return { ...state, [courseId]: newLectures };
    }
    case CREATE_LECTURE: {
      const { courseId, lecture } = action.payload;
      const lectures = state[courseId];
      return { ...state, [courseId]: [...lectures, lecture] };
    }
    case DELETE_LECTURE: {
      const { courseId, id } = action.payload;
      const lectures = state[courseId].filter(lecture => lecture.id !== id);
      return { ...state, [courseId]: lectures };
    }
    default:
      return state;
  }
};
