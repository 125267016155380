export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const CREATE_USER = "CREATE_USER";
export const SIGN_UP = "SIGN_UP";
export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const API_ACTION = "API_ACTION";
export const LOADING = "LOADING";
export const AUTH_USER = "AUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const GET_LECTURES = "GET_LECTURES";
export const CREATE_COURSE = "CREATE_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const GET_TEACHERS = "GET_TEACHERS";
export const GET_COURSES = "GET_COURSES";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_COURSES_BY_TYPE = "GET_COURSES_BY_TYPE";
export const GET_STUDENT_COURSES = "GET_STUDENT_COURSES";
export const CREATE_LECTURE = "CREATE_LECTURE";
export const UPDATE_LECTURE = "UPDATE_LECTURE";
export const DELETE_LECTURE = "DELETE_LECTURE";
export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const CREATE_ATTACHMENT = "CREATE_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const GET_LECTURE = "GET_LECTURE";
export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_SUBSCRIPTIONS = "DELETE_SUBSCRIPTIONS";
export const GET_COURSE_SUBSCRIPITIONS = "GET_COURSE_SUBSCRIPITIONS";
