import { GET_STUDENT_COURSES } from "./types";
import { startLoading, stopLoading } from "./";
import CoursesApi from "../api/CoursesApi";

export const getStudentsCourses = () => async dispatch => {
  dispatch(startLoading());
  const { data } = await CoursesApi().getCoursesForStudent();

  dispatch(stopLoading());
  dispatch({
    type: GET_STUDENT_COURSES,
    payload: data,
  });
};
