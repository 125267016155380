import { Toolbar, Box } from "@mui/material";
import IPE0 from "../shared/IPElogo";
import TemporaryDrawer from "../Drawer/Drawer";
import "./MobileTopBar.css";
import SearchIcon from "@mui/icons-material/Search";

export default function MobileTopBar({ setShowSearch }) {
  return (
    <Toolbar sx={{ backgroundColor: "#00a6a3" }}>
      <TemporaryDrawer
        sizeBig="large"
        sizeSmall="large"
        whiteVersion={true}
        setShowMobileSearchBar={setShowSearch}
        isMobile={true}
      />
      <Box
        mx="auto"
        sx={{
          paddingLeft: "1%",
          flex: 3,
        }}
      >
        <IPE0
          width={75}
          height={37.5}
          fill2="white"
          fill1="white"
          style={{
            padding: "0px",
            marginTop: "7px",
            marginLeft: "25vw",
          }}
        />
      </Box>
      <Box
        mx="auto"
        sx={{
          paddingLeft: "1%",
          flex: 3,
        }}
        onClick={() => setShowSearch(true)}
      >
        <SearchIcon
          width={75}
          height={37.5}
          fill2="white"
          fill1="white"
          style={{
            padding: "0px",
            marginTop: "7px",
            marginLeft: "25vw",
            color: "white",
          }}
        />
      </Box>
    </Toolbar>
  );
}
