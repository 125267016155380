import {
  GET_ATTACHMENTS,
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "../actions/types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ATTACHMENTS: {
      const { courseId, data } = action.payload;
      return { ...state, [courseId]: data.attachments };
    }
    case CREATE_ATTACHMENT: {
      const { courseId, attachment } = action.payload;

      const attachments = state[courseId];
      return { ...state, [courseId]: [...attachments, attachment] };
    }
    case DELETE_ATTACHMENT: {
      const { courseId, id } = action.payload;
      const attachments = state[courseId].filter(
        attachment => attachment.id !== id
      );
      return { ...state, [courseId]: attachments };
    }
    default:
      return state;
  }
};
