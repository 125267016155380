import auth from "./auth";
import user from "./user";
import lectures from "./lectures";
import teachers from "./teachers";
import course from "./course";
import courses from "./courses";
import users from "./users";
import subscriptions from "./subscriptions";
import loading from "./loading";
import attachments from "./attachments";
import courseSubscriptions from "./courseSubscriptions";

export default {
  auth,
  user,
  lectures,
  teachers,
  course,
  courses,
  users,
  subscriptions,
  loading,
  attachments,
  courseSubscriptions,
};
