import { DELETE_SUBSCRIPTIONS } from "./types";
import CoursesApi from "../api/CoursesApi";

export const deleteCourseSubscriptions = courseId => async dispatch => {
  const { data: _data } = await CoursesApi().deleteCourseSubscriptions(
    courseId
  );

  dispatch({
    type: DELETE_SUBSCRIPTIONS,
    payload: { courseId },
  });
};
