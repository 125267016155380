import { GET_TEACHERS } from "./types";
import UsersApi from "../api/UsersApi";

export const getTeachers = () => async dispatch => {
  const { data } = await UsersApi().getTeacherUsers();
  dispatch({
    type: GET_TEACHERS,
    payload: data,
  });
  return "Teachers obtenidos con éxito";
};
