import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IPElogo from "../shared/IPElogo";
import ListItemLink from "./ListItemLink";
import { logout } from "../../actions";
import {
  Drawer,
  IconButton,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const listItemSx = {
  "&:focus": { backgroundColor: "#014451", color: "#afd9c1" },
  "&:hover": { backgroundColor: "#014451", color: "#afd9c1" },
};

export default function TemporaryDrawer({
  setShowMobileSearchBar,
  whiteVersion,
  isMobile,
}) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.authenticated);
  const userRole = useSelector(state => state.user.role);
  const hasPermission = role => {
    return userRole === role;
  };
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const list = anchor => (
    <div
      style={{
        width: "250px",
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {isMobile ? (
          <ListItem
            button
            key={"BUSCAR"}
            sx={listItemSx}
            onClick={() => setShowMobileSearchBar(true)}
          >
            <ListItemText primary={"BUSCAR"} />
          </ListItem>
        ) : null}
        <a
          href="/coursesList/FCEA"
          style={{ color: "white", textDecoration: "none" }}
        >
          <ListItem button key={"FCEA"} sx={listItemSx}>
            <ListItemText primary={"FCEA"} />
          </ListItem>
        </a>
        <a
          href="/coursesList/FING"
          style={{ color: "white", textDecoration: "none" }}
        >
          <ListItem button key={"FING"} sx={listItemSx}>
            <ListItemText primary={"FING"} />
          </ListItem>
        </a>
        <a
          href="/coursesList/CORTOS"
          style={{ color: "white", textDecoration: "none" }}
        >
          <ListItem button key={"CURSOS y CARERRAS"} sx={listItemSx}>
            <ListItemText primary={"CURSOS y CARERRAS"} />
          </ListItem>
        </a>
        {!isAuthenticated ? (
          <>
            <ListItemLink
              text="INGRESAR"
              to="/login"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="REGISTRARSE"
              to="/signup"
              sx={listItemSx}
            ></ListItemLink>
          </>
        ) : null}
        <a
          href="https://ipe.edu.uy/contacto/"
          style={{ color: "white", textDecoration: "none" }}
        >
          <ListItem button key={"CONTACTO"} sx={listItemSx}>
            <ListItemText primary={"CONTACTO"} />
          </ListItem>
        </a>
      </List>
      <Divider />
      <List>
        <a
          href="https://www.instagram.com/ipe.instituto"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem button key={"INSTAGRAM"} sx={listItemSx}>
            <ListItemText primary={"INSTAGRAM"} />
          </ListItem>
        </a>
        <a
          href="https://www.facebook.com/instituto.ipe.7/"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem button key={"FACEBOOK"} sx={listItemSx}>
            <ListItemText primary={"FACEBOOK"} />
          </ListItem>
        </a>
        <a
          href="https://m.me/instituto.ipe.7/"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem button key={"MESSENGER"} sx={listItemSx}>
            <ListItemText primary={"MESSENGER"} />
          </ListItem>
        </a>
        <a
          href="https://ipe.edu.uy/"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem button key={"PÁGINA PRINCIPAL"} sx={listItemSx}>
            <ListItemText primary={"PÁGINA PRINCIPAL"} />
          </ListItem>
        </a>
        <Divider />
        {isAuthenticated ? (
          <ListItemLink
            text="PERFIL"
            to="/profile"
            sx={listItemSx}
          ></ListItemLink>
        ) : null}
        {isAuthenticated ? (
          <ListItemLink
            text="MIS MATERIAS"
            to="/my_courses"
            sx={listItemSx}
          ></ListItemLink>
        ) : null}
        {isAuthenticated && hasPermission("admin") ? (
          <>
            <ListItemLink
              text="CREAR USUARIOS"
              to="/createUser"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="CREAR MATERIAS"
              to="/createCourse"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="EDITAR MATERIAS"
              to="/courses"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="EDITAR USUARIOS Y SUBSCRIPCIONES"
              to="/subscriptions"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="SESIONES"
              to="/sessions"
              sx={listItemSx}
            ></ListItemLink>
            <ListItemLink
              text="CLASES VISTAS"
              to="/impressions"
              sx={listItemSx}
            />
          </>
        ) : null}
        {isAuthenticated ? (
          <ListItemLink
            text="CUESTIONARIOS"
            to="/quizzes"
            sx={listItemSx}
          ></ListItemLink>
        ) : null}
        {isAuthenticated ? (
          <ListItem button>
            <ListItemText
              primary={"CERRAR SESION"}
              sx={listItemSx}
              onClick={() => dispatch(logout())}
            />
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  return (
    <div>
      <>
        <IconButton
          onClick={toggleDrawer("left", true)}
          aria-label="Menu principal"
        >
          <MenuIcon
            style={{
              fill: whiteVersion ? "white" : "black",
            }}
          />
        </IconButton>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              backgroundColor: "#00a6a3",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "Left",
              paddingLeft: "10px",
              paddingBottom: "1.6px",
            }}
          >
            <div style={{ flex: "1" }}>
              <IconButton onClick={toggleDrawer("left", false)}>
                <MenuIcon style={{ fill: "white" }} />
              </IconButton>
            </div>
            <div
              style={{
                flex: "3",
                paddingLeft: "14.5px",
                alignItems: "center",
              }}
            >
              <a href={"/"} style={{ color: "white", textDecoration: "none" }}>
                <IPElogo
                  width={80}
                  height={40}
                  fill2="white"
                  fill1="white"
                  style={{
                    paddingTop: "6.6px",
                    paddingLeft: "1px",
                    margin: "0px",
                  }}
                />
              </a>
            </div>
          </div>
          <Divider />
          {list("left")}
        </Drawer>
      </>
    </div>
  );
}
