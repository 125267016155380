import { GET_COURSES_BY_TYPE } from "./types";
import CoursesApi from "../api/CoursesApi";

export const getCoursesBytype = type => async dispatch => {
  const { data } = await CoursesApi().getCoursesBytype(type);
  dispatch({
    type: GET_COURSES_BY_TYPE,
    payload: data,
  });
  return "Cursos por tipo obtenidos con éxito";
};
