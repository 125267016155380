import { ApiService } from "./base";

export default function CoursesApi() {
  const getCourses = async token => {
    const body = { token };
    const user = await ApiService().get("/courses", body);
    return user;
  };
  const getCoursesBytype = async type => {
    const body = {};
    const user = await ApiService().get(`/courses/byType/${type}`, body);
    return user;
  };

  const updateCourse = async course => {
    const body = {
      course,
    };
    const user = await ApiService().put("/courses", body);
    return user;
  };
  const deleteCourse = async course => {
    const courseId = course.id;
    const user = await ApiService().delete(`/courses/${courseId}`);
    return user;
  };
  const deleteCourseSubscriptions = async courseId => {
    const user = await ApiService().delete(
      `/courses/${courseId}/subscriptions`
    );
    return user;
  };
  const createCourse = async (
    name,
    description,
    objective,
    groups,
    type,
    category
  ) => {
    const body = {
      name,
      description,
      objective,
      groups,
      type,
      category,
    };
    const user = await ApiService().post("/courses", body);
    return user;
  };

  const getCoursesForStudent = async () => {
    const data = await ApiService().get("/courses/my_courses");
    return data;
  };

  const getCourseSubscriptions = async courseId => {
    const data = await ApiService().get(`/courses/${courseId}/subscriptions`);
    return data;
  };

  return {
    createCourse,
    updateCourse,
    deleteCourse,
    getCourses,
    getCoursesBytype,
    getCoursesForStudent,
    getCourseSubscriptions,
    deleteCourseSubscriptions,
  };
}
