import { UPDATE_LECTURE } from "./types";
import LecturesApi from "../api/LecturesApi";

export const deleteVideo = (courseId, id) => async dispatch => {
  const lecture = await new LecturesApi().deleteVideo(id, courseId);
  dispatch({
    type: UPDATE_LECTURE,
    payload: { courseId, id, data: lecture.data },
  });
};
