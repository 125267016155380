import { ApiService } from "./base";

export default function UsersApi() {
  const updateUser = async (userId, body) => {
    const user = await ApiService().put(`/users/${userId}`, body);
    return user;
  };

  const deleteUser = async user => {
    const userId = user.id;
    const res = await ApiService().delete(`/users/${userId}`);
    return res;
  };
  const changePassword = async body => {
    const user = await ApiService().put("/users/change_password", body);
    return user;
  };

  const adminChangePassword = async body => {
    const user = await ApiService().put("/users/change_password_admin", body);
    return user;
  };

  const adminChangeName = async body => {
    const user = await ApiService().put("/users/change_name_admin", body);
    return user;
  };

  const adminChangeEmail = async body => {
    const user = await ApiService().put("/users/change_email_admin", body);
    return user;
  };

  const requestPasswordReset = async body => {
    const request = await ApiService().post(
      "/users/request_forgot_password",
      body
    );
    return request;
  };

  const resetPassword = async ({ token, password }) => {
    const request = await ApiService().post("/users/verify_forgot_password", {
      token,
      password,
    });
    return request;
  };

  const getUser = async userId => {
    const response = await ApiService().get(`/users/${userId}`);
    return response;
  };

  const userSignUp = async (username, email, password) => {
    const body = { username, email, password };
    const user = await ApiService().post("/users", body);
    return user;
  };

  const login = async (email, password, fingerprint) => {
    const body = { email, password, fingerprint };
    const user = await ApiService().post("/users/login", body);
    return user;
  };

  const sessionAlive = async () => {
    return await ApiService().get("/users/session");
  };

  const getTeacherUsers = async () => {
    const teachers = await ApiService().get("/users/teachers");
    return teachers;
  };
  const getUsers = async () => {
    const users = await ApiService().get("/users");
    return users;
  };

  const createUser = async body => {
    const user = await ApiService().post("/users/createUser", body);
    return user;
  };

  const activateUser = async token => {
    const response = await ApiService().post("/users/activate", { token });
    return response.data;
  };

  const requestActivationEmail = async email => {
    const response = await ApiService().post("/users/resend_activation", {
      email,
    });
    return response.data;
  };

  return {
    userSignUp,
    login,
    createUser,
    getUser,
    updateUser,
    changePassword,
    requestPasswordReset,
    resetPassword,
    getTeacherUsers,
    getUsers,
    activateUser,
    requestActivationEmail,
    deleteUser,
    sessionAlive,
    adminChangePassword,
    adminChangeName,
    adminChangeEmail,
  };
}
