import {
  LOGIN,
  GET_USER_DATA,
  UPDATE_USER_DATA,
  CREATE_USER,
  DELETE_USER,
} from "../actions/types";

const INITIAL_STATE = {
  ci: "",
  email: "",
  id: "",
  name: "",
  role: "",
  username: "",
  birthDate: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN: {
      const { ci, email, id, name, role, username } = action.payload;
      return { ...state, ci, email, id, name, role, username };
    }
    case GET_USER_DATA: {
      const { name, ci, birthDate, email } = action.payload;
      return { ...state, name, ci, birthDate, email };
    }
    case UPDATE_USER_DATA: {
      const { name, ci, email } = action.payload;
      return { ...state, name, ci, email };
    }
    case CREATE_USER: {
      const {
        name,
        email,
        userName,
        password,
        birthDate,
        ci,
        role,
      } = action.payload;
      return { ...state, name, email, userName, password, birthDate, ci, role };
    }
    case DELETE_USER: {
      return { ...state };
    }
    default:
      return state;
  }
};
