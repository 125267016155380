import { DELETE_ATTACHMENT } from "./types";
import AttachmentsApi from "../api/AttachmentsApi";

export const deleteAttachment = (courseId, id) => async dispatch => {
  await new AttachmentsApi().deleteAttachment(courseId, id);
  dispatch({
    type: DELETE_ATTACHMENT,
    payload: { courseId, id },
  });
};
