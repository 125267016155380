import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";

const inputSx = {
  color: "inherit",
  flex: 1,
  ".MuiInputBase-input": {
    padding: theme => theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme => `calc(1em + ${theme.spacing(4)})`,
    transition: theme => theme.transitions.create("width"),
    width: "100%",
  },
};

export default function SearchBar({ onSearch, search }) {
  return (
    <div style={{ flexGrow: 1 }}>
      <Box
        sx={theme => ({
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.common.white, 0.15),
          "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
          },
          marginRight: theme.spacing(2),
          marginLeft: 0,
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
          },
        })}
      >
        <Box
          sx={{
            padding: theme => theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SearchIcon />
        </Box>
        <InputBase
          placeholder="Search…"
          sx={inputSx}
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={event => onSearch(event.target.value)}
        />
      </Box>
    </div>
  );
}
