import {
  GET_COURSE_SUBSCRIPITIONS,
  DELETE_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
} from "../actions/types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSE_SUBSCRIPITIONS: {
      const { subscriptions, courseId } = action.payload;
      return { ...state, [courseId]: subscriptions.subscriptions };
    }
    case DELETE_SUBSCRIPTION: {
      const { userId, courseId } = action.payload;
      return {
        ...state,
        [courseId]: state[courseId].filter(sub => sub.userId !== userId),
      };
    }
    case ADD_SUBSCRIPTION: {
      const previousSubscriptions = state[action.payload.courseId] || [];
      return {
        ...state,
        [action.payload.courseId]: [...previousSubscriptions, action.payload],
      };
    }
    case DELETE_SUBSCRIPTIONS: {
      const { courseId } = action.payload;
      return {
        ...state,
        [courseId]: [],
      };
    }
    default:
      return state;
  }
};
