import { useEffect } from "react";
import { Events } from "react-scroll";
import IpeBanner from "../../svg/IpeBannerv6.svg";
import bgfcea from "../../images/png/fcea_manchas-01.png";
import fcea from "../../images/png/fcea_frente-01-01.png";
import bgfing from "../../images/png/fing_manchas-01-01.png";
import fing from "../../images/png/fing_frente-01-01.png";
import bgcursos from "../../images/png/cursos_manchas-01.png";
import cursos from "../../images/png/cursos_frente-01.png";
import time from "../../svg/time.svg";
import lesson from "../../svg/lesson.svg";
import feed from "../../svg/feedback.svg";
import various from "../../svg/various.svg";

import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Button,
} from "@mui/material";
import "./Home.css";

const paperSx = {
  width: "100%",
  borderRadius: "16px",
};

const solidButtonSx = {
  backgroundColor: "#00a6a3",
  color: "white",
  "&:hover": { backgroundColor: "#2F6670" },
  minWidth: "335px",
  minHeight: "60px",
  margin: "8px",
  "&:hover": { backgroundColor: "#014451", color: "#afd9c1" },
};

function Home({ onCourseCategoryClick }) {
  useEffect(() => {
    Events.scrollEvent.register("begin", function() {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function() {
      console.log("end", arguments);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <div className="cont">
      <div className="banner">
        <img src={IpeBanner} width="100%" overflow="auto" alt="Banner" />
      </div>
      <div className="wrap">
        <div className="uniIcons">
          <div
            className="CourseCardContainer"
            style={{ borderColor: "#680d36" }}
          >
            <div
              className="CourseCardBack"
              style={{ backgroundColor: "#680d36" }}
            >
              <div style={{ backgroundImage: `url(${bgfcea})` }}>
                <img src={fcea} alt="FCEA" />
                <Button onClick={() => onCourseCategoryClick("FCEA")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>

          <div
            className="CourseCardContainer"
            style={{ borderColor: "#004169" }}
          >
            <div className="CourseCardBack" style={{ background: "#004169" }}>
              <div style={{ backgroundImage: `url(${bgfing})` }}>
                <img src={fing} alt="FING" />
                <Button onClick={() => onCourseCategoryClick("FING")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>

          <div
            className="CourseCardContainer"
            style={{ borderColor: "#00a6a2" }}
          >
            <div className="CourseCardBack" style={{ background: "#00a6a2" }}>
              <div style={{ backgroundImage: `url(${bgcursos})` }}>
                <img src={cursos} alt="Cursor Cortos" />
                <Button onClick={() => onCourseCategoryClick("CORTOS")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="benefitsBox sec">
          <div className="IpeOnline title">
            <Typography variant="h2" gutterBottom>
              IPE Online
            </Typography>
          </div>
          <div className="benefits top">
            <div className="left info">
              <Card sx={paperSx}>
                <div className="CardContent">
                  <div className="imgs">
                    <CardMedia component="img" src={time} height="100%" />
                  </div>
                  <div className="CardText">
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        A tu ritmo
                      </Typography>
                      <Typography gutterBottom variant="h6" component="p">
                        Para que estudies en el momento del día que prefieras
                        donde prefieras.
                      </Typography>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </div>
            <div className="right info">
              <Card sx={paperSx}>
                <div className="CardContent">
                  <div className="imgs">
                    <CardMedia component="img" src={lesson} height="100%" />
                  </div>
                  <div className="CardText">
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Docentes
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        Preparate para la carrera de la mejor manera y con los
                        mejores profesores.
                      </Typography>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="benefits bot">
            <div className="left info">
              <Card sx={paperSx}>
                <div className="CardContent">
                  <div className="imgs">
                    <CardMedia component="img" src={various} height="100%" />
                  </div>
                  <div className="CardText">
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Amplia oferta
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        Para que encuentres cursos a tu gusto, sea de facultad o
                        carreras cortas.
                      </Typography>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </div>
            <div className="right info">
              <Card sx={paperSx}>
                <div className="CardContent">
                  <div className="imgs">
                    <CardMedia component="img" src={feed} height="100%" />
                  </div>
                  <div className="CardText">
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Mejora continua
                      </Typography>
                      <Typography gutterBottom variant="h6" component="h2">
                        Apreciamos tus recomendaciones para traerte el mejor
                        servicio.
                      </Typography>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="join sec">
          <div className="joinNow title">
            <Typography variant="h2" gutterBottom>
              Únete a IPE Online hoy
            </Typography>
          </div>
          <div className="listWrapper">
            <div className="list">
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("FCEA")}
                >
                  Facultad de Economía
                </Button>
              </Box>
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("FING")}
                >
                  Facultad de Ingeniería
                </Button>
              </Box>
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("CORTOS")}
                >
                  Cursos Y Carreras cortas
                </Button>
              </Box>
              <Box mx="auto">
                <Button sx={solidButtonSx} variant="contained" disableElevation>
                  Contacto y sugerencias
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
