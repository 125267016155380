import { CREATE_COURSE } from "./types";
import CoursesApi from "../api/CoursesApi";

export const createCourse = (
  name,
  description,
  objective,
  groups,
  type,
  category
) => async dispatch => {
  const { data } = await CoursesApi().createCourse(
    name,
    description,
    objective,
    groups,
    type,
    category
  );

  dispatch({
    type: CREATE_COURSE,
    payload: data,
  });
};
