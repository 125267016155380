import { Paper, Typography, Grid, Box } from "@mui/material";
import patternHome from "../../images/pattern-home.png";
import "./LinkListElement.scss";

export default function CourseListPage({ courses }) {
  return (
    <div
      style={{
        flexGrow: 1,
        backgroundImage: `url(${patternHome})`,
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        padding: "40px 0 80px",
      }}
    >
      <h1 style={{ margin: "0 auto 40px" }}>Lista de cursos</h1>
      <Box
        sx={theme => ({
          margin: "0 auto 40px",
          width: "fit-content",
          fontSize: "20px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
          },
          "& p": {
            background: "#fff",
            padding: "16px",
            textAlign: "center",
            margin: "0 12px",
            border: "1px solid #eee",
          },
        })}
      >
        <p>
          Para comenzar tu curso debes{" "}
          <a href="/signup" style={{ color: "#0075ff" }}>
            registrarte
          </a>{" "}
          y dirigirte a <span style={{ fontWeight: "bold" }}>Mis materias</span>
        </p>
      </Box>
      <Grid container>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.086) 0px 3px 20px",
            margin: "0 auto",
            padding: " 16px",
            background: "#fff",
            width: "88vw",
          }}
        >
          {courses ? (
            courses.map((course, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <Paper className="paper" elevation={0} square>
                    <div
                      style={{
                        display: "flex",
                        padding: "32px 16px",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {/* <Typography
                        className={`course-link-text ${classes.title}`}
                      >
                        {"Nombre: "}
                      </Typography> */}
                        <Typography
                          className="course-link-text"
                          sx={{ flex: 1 }}
                          style={{ fontWeight: "bold" }}
                        >
                          {course.name}
                        </Typography>
                      </div>
                      <div style={{ flex: 1 }}>
                        {/* <Typography
                        className={`course-link-text ${classes.title}`}
                      >
                        {"Tema: "}
                      </Typography> */}
                        <Typography
                          className="course-link-text"
                          sx={{ flex: 1 }}
                        >
                          {course.category}
                        </Typography>
                      </div>

                      {course.description ? (
                        <div style={{ flex: 1 }}>
                          {/* <Typography
                          className={`course-link-text ${classes.title}`}
                        >
                          {"Descripción: "}
                        </Typography> */}
                          <Typography
                            className="course-link-text"
                            sx={{ flex: 1 }}
                          >
                            {course.description}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </Paper>
                </Grid>
              );
            })
          ) : (
            <h1>No hay cursos disponibles</h1>
          )}
        </div>
      </Grid>
    </div>
  );
}
