import { ApiService } from "./base";

export default function AttachmentsApi() {
  const getAttachments = async courseId => {
    const response = await ApiService().get(`/courses/${courseId}/attachments`);
    return response;
  };

  const createAttachment = async (courseId, body) => {
    const response = await ApiService().post(
      `/courses/${courseId}/attachments`,
      body
    );
    return response;
  };

  const deleteAttachment = async (courseId, attachmentId) => {
    const response = await ApiService().delete(
      `/courses/${courseId}/attachments/${attachmentId}`
    );
    return response;
  };

  return {
    getAttachments,
    createAttachment,
    deleteAttachment,
  };
}
