import { ADD_SUBSCRIPTION } from "./types";
import SubscriptionsApi from "../api/SubscriptionsApi";

export const addSubscription = (body, courseId) => async dispatch => {
  const { data } = await SubscriptionsApi().addSubscription(body, courseId);
  dispatch({
    type: ADD_SUBSCRIPTION,
    payload: data,
  });
};
