import { GET_COURSE_SUBSCRIPITIONS } from "./types";
import CoursesApi from "../api/CoursesApi";

export const getCourseSubscriptions = courseId => async dispatch => {
  const { data } = await CoursesApi().getCourseSubscriptions(courseId);

  dispatch({
    type: GET_COURSE_SUBSCRIPITIONS,
    payload: { subscriptions: data, courseId },
  });
};
