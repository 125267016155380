import { UPDATE_USER_DATA } from "./types";
import { getUserId } from "../services/LocalStorageService";
import UsersApi from "../api/UsersApi";

export const updateUser = body => async dispatch => {
  const userId = getUserId();
  await UsersApi().updateUser(userId, body);
  dispatch({
    type: UPDATE_USER_DATA,
    payload: body,
  });
  return "Datos actualizados con éxito";
};
