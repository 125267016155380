import "./MobileTopBar.css";
import SearchBar from "../SearchBar/SearchBar";
import { Box, Slide, Toolbar, AppBar } from "@mui/material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

function HideOnScroll({ showBar, ...props }) {
  const { children } = props;

  return (
    <Slide appear={false} direction="down" in={showBar}>
      {children}
    </Slide>
  );
}

export default function HideAppBar({
  onSearch,
  search,
  showBar,
  setShowBar,
  ...props
}) {
  return (
    <HideOnScroll showBar={showBar} {...props}>
      <AppBar color="inherit">
        <Toolbar>
          <Box
            mx="auto"
            sx={{ paddingLeft: "1%" }}
            onClick={() => setShowBar(false)}
          >
            <KeyboardArrowUp />
          </Box>
          <Box mx="auto" sx={{ paddingLeft: "1%" }}>
            <SearchBar onSearch={onSearch} search={search} />
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
