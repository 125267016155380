import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { getLectures } from "../actions";
import LinkListElement from "../components/LinkListElement";

export default function LectureListPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const lectures = useSelector(state => state.lectures[courseId]);

  useEffect(() => {
    dispatch(getLectures(courseId));
  }, [dispatch, courseId]);

  return (
    <div className="mx-3">
      {(lectures || []).map(({ id, title, description }) => (
        <LinkListElement
          key={id}
          text={title}
          to={`/lectures/${courseId}/${id}`}
        >
          <br />
          <Typography variant="caption">{description}</Typography>
        </LinkListElement>
      ))}
    </div>
  );
}
