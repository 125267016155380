import "./MobileTopBar.css";
import IPE0 from "../shared/IPElogo";
import TemporaryDrawer from "../Drawer/Drawer";
import { Box, Slide, useScrollTrigger, Toolbar, AppBar } from "@mui/material";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true,
  });

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

export default function HideAppBar(props) {
  return (
    <HideOnScroll {...props}>
      {/* <TopBarSmall /> */}
      <AppBar
        color="inherit"
        // style={{ background: "transparent", boxShadow: "white" }}
      >
        <Toolbar>
          <TemporaryDrawer />
          <Box mx="auto" sx={{ paddingLeft: "1%" }}>
            <IPE0
              width={80}
              height={40}
              fill1="grey"
              fill2="#00a6a3"
              style={{ padding: "0px", marginTop: "7px" }}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
