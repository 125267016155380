import "./TopBar.css";
import IPE0 from "../shared/IPElogo";
import TemporaryDrawer from "../Drawer/Drawer";
import SearchBar from "../SearchBar/SearchBar";
import {
  Box,
  Button,
  Slide,
  useScrollTrigger,
  Toolbar,
  AppBar,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions";
import { Link } from "react-router-dom";

const solidButtonSx = {
  backgroundColor: "#014451",
  color: "white",
  "&:hover": { backgroundColor: "#2F6670" },
};

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true,
  });

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

export default function HideAppBar({ onSearch, search, ...props }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.authenticated);

  return (
    <HideOnScroll {...props}>
      <AppBar color="inherit">
        <Toolbar>
          <TemporaryDrawer />
          <Box mx="auto" sx={{ paddingLeft: "1%" }}>
            <a href="/">
              <IPE0
                width={80}
                height={40}
                fill1="grey"
                fill2="#00a6a3"
                style={{ padding: "0px", marginTop: "7px" }}
              />
            </a>
          </Box>
          <SearchBar onSearch={onSearch} search={search} />
          {!isAuthenticated ? (
            <>
              {" "}
              <Link style={{ textDecoration: "none" }} to="/login">
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #014451",
                    color: "#014451",
                  }}
                  size="medium"
                >
                  INGRESAR
                </Button>
              </Link>
              <Box mx="auto" sx={{ paddingLeft: "1%" }}>
                <Link style={{ textDecoration: "none" }} to="/signup">
                  <Button
                    size="medium"
                    sx={solidButtonSx}
                    variant="contained"
                    disableElevation
                  >
                    REGISTRARSE
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <Box mx="auto" sx={{ paddingLeft: "1%" }}>
              <Button
                size="medium"
                sx={solidButtonSx}
                variant="contained"
                disableElevation
                onClick={() => dispatch(logout())}
              >
                CERRAR SESION
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
