import { useState, useEffect } from "react";
import CourseList from "../components/CourseList/CourseList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../actions";

export default function CourseListPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const storeCourses = useSelector(state => state.courses.courses);
  const [courses, setCourses] = useState(storeCourses || []);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState(params.searchTerm);

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  useEffect(() => {
    setCourses(storeCourses);
  }, [storeCourses]);
  useEffect(() => {
    setSearchTerm(params.searchTerm);
  }, [params.searchTerm]);

  useEffect(() => {
    setFilteredCourses(
      courses
        ? courses.filter(course => {
            return (
              course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              course.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
              course.category.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        : []
    );
  }, [searchTerm, courses]);

  return <CourseList courses={filteredCourses}></CourseList>;
}
