import { DELETE_LECTURE } from "./types";
import LecturesApi from "../api/LecturesApi";

export const deleteLecture = (courseId, id) => async dispatch => {
  await new LecturesApi().deleteLecture(id, courseId);
  dispatch({
    type: DELETE_LECTURE,
    payload: { courseId, id },
  });
};
