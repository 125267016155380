import BckGndLaptopGirl01 from "../../images/BckGndLaptopGirl-01.webp";
import bgfcea from "../../images/png/fcea_manchas-01.png";
import fcea from "../../images/png/fcea_frente-01-01.png";
import bgfing from "../../images/png/fing_manchas-01-01.png";
import fing from "../../images/png/fing_frente-01-01.png";
import bgcursos from "../../images/png/cursos_manchas-01.png";
import cursos from "../../images/png/cursos_frente-01.png";
import time from "../../svg/time.svg";
import lesson from "../../svg/lesson.svg";
import feed from "../../svg/feedback.svg";
import various from "../../svg/various.svg";
import CustomCard from "../CustomCard/CustomCard";
import { Link } from "react-scroll";

import { Box, Button } from "@mui/material";
import "./MobileHome.css";

const solidButtonSx = {
  backgroundColor: "#00a6a3",
  color: "white",
  "&:hover": { backgroundColor: "#2F6670" },
  width: "268px",
  minHeight: "60px",
  margin: "8px",
  "&:hover": { backgroundColor: "#014451", color: "#afd9c1" },
};

function MobileHome(props) {
  const { onCourseCategoryClick } = props;
  return (
    <div className="cont">
      <div
        className=""
        style={{
          backgroundImage: "url(" + BckGndLaptopGirl01 + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          height: "172vw",
        }}
      >
        <div
          style={{
            margin: "44vw auto 0",
            width: "fit-content",
            background: "white",
          }}
        >
          <Link
            to="icons-section"
            href="#icons-section"
            spy={true}
            smooth={true}
            duration={500}
            className="reset-link"
            activeClass="some-active-class"
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "white",
                margin: "3px",
                fontFamily: "BebasNeue",
                fontSize: "28px",
                color: "rgb(14, 88, 125)",
                lineHeight: "36px",
                borderRadius: 0,
                border: "3px solid rgb(14, 88, 125)",
                "&:active": {
                  backgroundColor: "rgb(14, 88, 125)",
                  color: "#fff",
                },
              }}
            >
              {" "}
              ¡Comienza Ya!
            </Button>
          </Link>
        </div>
      </div>
      <div className="wrap">
        <div className="mobile-uniIcons" id="icons-section">
          <div
            className="CourseCardContainer"
            style={{ borderColor: "#680d36" }}
          >
            <div
              className="CourseCardBack"
              style={{ backgroundColor: "#680d36" }}
            >
              <div style={{ backgroundImage: `url(${bgfcea})` }}>
                <img src={fcea} alt="FCEA" />
                <Button onClick={() => onCourseCategoryClick("FCEA")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>
          <div
            className="CourseCardContainer"
            style={{ borderColor: "#004169" }}
          >
            <div className="CourseCardBack" style={{ background: "#004169" }}>
              <div style={{ backgroundImage: `url(${bgfing})` }}>
                <img src={fing} alt="FING" />
                <Button onClick={() => onCourseCategoryClick("FING")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>

          <div
            className="CourseCardContainer"
            style={{ borderColor: "#00a6a2" }}
          >
            <div className="CourseCardBack" style={{ background: "#00a6a2" }}>
              <div style={{ backgroundImage: `url(${bgcursos})` }}>
                <img src={cursos} alt="Cursos Cortos" />
                <Button onClick={() => onCourseCategoryClick("CORTOS")}>
                  Ver cursos
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="benefitsBox sec">
          <div className="IpeOnline title">
            <h1>IPE Online</h1>
          </div>
          <div className="benefits top">
            <div className="left info">
              <CustomCard
                img={time}
                title={"A tu ritmo"}
                text={
                  "Para que estudies en el momento del día que prefieras donde prefieras."
                }
              />
            </div>
            <div className="right info">
              <CustomCard
                img={lesson}
                title={"Docentes"}
                text={
                  "Preparate para la carrera de la mejor manera y con los mejores profesores."
                }
              />
            </div>
          </div>
          <div className="benefits bot">
            <div className="left info">
              <CustomCard
                img={various}
                title={"Amplia oferta"}
                text={
                  "Para que encuentres cursos a tu gusto, sea de facultad o carreras cortas."
                }
              />
            </div>
            <div className="right info">
              <CustomCard
                img={feed}
                title={"Mejora continua"}
                text={
                  "Apreciamos tus recomendaciones para traerte el mejor servicio."
                }
              />
            </div>
          </div>
        </div>
        <div className="join sec">
          <div className="joinNow title">
            <h1>Únete a IPE Online hoy!</h1>
          </div>
          <div className="listWrapper">
            <div className="list">
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("FCEA")}
                >
                  Facultad de Economía
                </Button>
              </Box>
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("FING")}
                >
                  Facultad de Ingeniería
                </Button>
              </Box>
              <Box mx="auto">
                <Button
                  sx={solidButtonSx}
                  variant="contained"
                  disableElevation
                  onClick={() => onCourseCategoryClick("CORTOS")}
                >
                  Cursos Y Carreras cortas
                </Button>
              </Box>
              <Box mx="auto">
                <Button sx={solidButtonSx} variant="contained" disableElevation>
                  Contacto y sugerencias
                </Button>
              </Box>
            </div>
          </div>
          {/* <a onClick={scrollToTop}>To the top!</a> */}
        </div>
      </div>
    </div>
  );
}

export default MobileHome;
