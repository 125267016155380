import { GET_USER_DATA } from "./types";
import { getUserId } from "../services/LocalStorageService";
import UsersApi from "../api/UsersApi";

export const getUser = () => async dispatch => {
  const userId = getUserId();
  const { data } = await UsersApi().getUser(userId);
  dispatch({
    type: GET_USER_DATA,
    payload: data,
  });
};
