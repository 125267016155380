import UsersApi from "../api/UsersApi";

export const createUser = body => async _dispatch => {
  const { data: _data } = await UsersApi().createUser(body);

  // no hay que hacer dispatch de nada al crear usuario, la accion CREATE_USER
  // sobre escribe el estado actual del usuario.

  // dispatch({
  //   type: CREATE_USER,
  //   payload: {},
  // });
};
