import "./LinkListElement.scss";
import { Link } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";

export default function LinkListElement({ to, text, children }) {
  return (
    <Box mt={2} mb={2}>
      <Link className="course-link" to={to}>
        <Paper
          className="paper"
          style={{
            margin: "0 auto",
            padding: 30,
            maxWidth: 600,
          }}
          elevation={3}
        >
          <Typography className="course-link-text">{text}</Typography>
          {children}
        </Paper>
      </Link>
    </Box>
  );
}
