import { GET_ATTACHMENTS } from "./types";
import AttachmentsApi from "../api/AttachmentsApi";

export const getAttachments = courseId => async dispatch => {
  const { data } = await AttachmentsApi().getAttachments(courseId);
  dispatch({
    type: GET_ATTACHMENTS,
    payload: { data, courseId },
  });
};
