import IPElogo from "../shared/IPElogo";
import { FaFacebook, FaFacebookMessenger, FaInstagram } from "react-icons/fa";
import {
  IconButton,
  Typography,
  Link,
  ButtonGroup,
  Button,
  Box,
} from "@mui/material";
import "./Footer.css";

function _Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footWrap">
        <div className="footInfo">
          <div className="logo">
            <IPElogo
              width={100}
              height={50}
              fill1="white"
              fill2="white"
              style={{ padding: "0px", marginTop: "7px" }}
            />
          </div>
          <div className="info">
            <Typography variant="h6" component="p" display="block" gutterBottom>
              Somos un instituto dedicado a la capacitación y a brindar cursos
              de apoyo para facultad.
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              display="block"
              gutterBottom
            >
              Por más información diríjete a nuestro sitio principal
            </Typography>
          </div>
          <div className="socMedia">
            <IconButton>
              <Link
                href="https://www.facebook.com/instituto.ipe.7/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook size="35px" color="white" />
              </Link>
            </IconButton>
            <IconButton>
              <Link
                href="https://www.instagram.com/ipe.instituto/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram size="35px" color="white" />
              </Link>
            </IconButton>
            <IconButton>
              <Link
                href="https://m.me/instituto.ipe.7/"
                target="_blank"
                aria-label="Facebook Messenger"
              >
                <FaFacebookMessenger size="35px" color="white" />
              </Link>
            </IconButton>
          </div>
          <Box
            sx={{
              display: "flex",
              color: "white",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                margin: theme => theme.spacing(1),
              },
            }}
          >
            <ButtonGroup
              variant="text"
              color="inherit"
              aria-label="text primary button group"
            >
              <Button>
                <a
                  href="/"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Inicio
                </a>
              </Button>
              <Button>
                <a
                  href="https://ipe.edu.uy/"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Sitio principal
                </a>
              </Button>
              <Button>
                <a
                  href="https://ipe.edu.uy/contacto"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Contacto
                </a>
              </Button>
            </ButtonGroup>
          </Box>
        </div>

        <div className="CRight">
          <Typography variant="overline" display="block" gutterBottom>
            Instituto IPE | Todos los derechos reservados - Desarrollado por
            Labor Development & Technology
          </Typography>
        </div>
      </div>
    </footer>
  );
}
