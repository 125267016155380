import { SIGN_UP } from "./types";
import UsersApi from "../api/UsersApi";

export const signUp = (username, email, password) => async dispatch => {
  try {
    const { data } = await UsersApi().userSignUp(username, email, password);
    dispatch({
      type: SIGN_UP,
      payload: data,
    });
  } catch (error) {
    return error.response.data;
  }
};
