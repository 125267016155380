import { GET_LECTURES } from "./types";
import LecturesApi from "../api/LecturesApi";

export const getLectures = courseId => async dispatch => {
  const { data } = await LecturesApi().getLectures(courseId);
  dispatch({
    type: GET_LECTURES,
    payload: { data, courseId },
  });
};
